#partners .activities {
    border-radius: 100%;
    width: 200px;
    height: 200px;
    background: #F3F3F3;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    margin: 15px;
}  

#partners h1{
    position: relative;
    top: -70px;  
    
}


#partners h2{
    font-family: Google sans;
    position: relative;
    top:80px;
    left:20px;
}

#partners a{
    text-decoration: none;
    color: black;
}

@media (max-width: 900px) {
    #partners .img-2 {
        width: 0;
        height: 0;
    }

    #partners .img-3 {
        width: 0;
        height: 0;
    }

    #partners .img-4 {
        width: 0;
        height: 0;
    
    }
}

@media (max-width: 900px){
    #partners h2{
        position: relative;
        font-size:2.5rem;
        left:10px;
    }
}