#contacts {
    margin-bottom: 100px;
}

#contacts h2 {
    margin-top: -100px;
    margin-bottom: 150px;
    font-weight: 700;
}

#contacts .contacts-card {
    padding: 100px 80px 80px 80px;
    width: 100%;
    height: 450px;
    background: #00283F;
    border-radius: 6px;
}

#contacts .contacts-card > .card-inner {
    color: white;
}

#contacts .contacts-card > .card-inner > h4 {
    margin-bottom: 30px;
}

#contacts .contacts-card > .card-inner .contacts-icon {
    margin-right: 20px;
}

#contacts .contacts-form {
    margin: -55px 0 0 -30px;
    padding: 120px 50px;
    border-radius: 6px;
    background: #FCFCFC;
    box-shadow: -3px 10px 15px rgba(0, 0, 0, 0.25);
}

#contacts .contacts-form input {
    border: none;
    margin-top: 30px;
    background: #FCFCFC;
}

#contacts .contacts-form hr {
    margin: 0;
    padding: 0;
}

#contacts .send-btn {
    margin: 50px 0 30px 0;
    padding: 5px;
    width:550px;
    background: #00283F;
    color: white;
    border-radius: 5px;
}

#contacts iframe {
    width: 100%;
    margin-top: 150px;
    margin-bottom: 50px;
}

@media (max-width: 770px) {
    #contacts .facts-section {
        display: flex;
        flex-direction: column-reverse;        
    }

    #contacts .send-btn {
        width:400px;
        margin-left:-10px;
    }

    #contacts h2 {
        margin-top: -100px;
        margin-bottom: 80px;
        font-weight: 700;
    }

    #contacts .contacts-form {
        margin: 0;
        padding: 50px 20px;
        border-radius: 0;
        box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
    }

    #contacts .contacts-card {
        border-radius: 0;
    }
}