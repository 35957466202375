/*стили иконок соц.сетей*/

.social-icons {
    display: flex;
    flex-direction: row;
    // justify-content: center;

    i {
      color:#00283F;
    }

    & > .icon-foo {      
      width: 25px;
      height: 25px;
      border-radius: 46px;
      background-color:white;
      display: flex;
      align-items: center;
      justify-content: center;     
  
      &:not(:last-child) {
        margin-bottom: 12px;
        margin-right: 12px;
      }
  
      &:hover {
        background-color: gray;
        text-decoration: none;
      }
    }
  }

 @media (max-width: 990px) {
   .social-icons {
     justify-content: center;
   }
 } 
  