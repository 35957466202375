
#navigation-bar-mobile {
  display: none;
}

#navigation-bar-mobile a {
  color: white !important;
}

#navigation-bar-mobile .navbar-nav {
  flex-direction: row;
}

#navigation-bar-mobile .navbar-nav > * {
  margin-right: 24px;
}

/* #navigation-bar-mobile select {

  border: none;
  background: green;
  color: black;
}

#navigation-bar-mobile select > option {
  border: none;
  padding: 5px;
  background: #FFFFFF;
  color: #00283F;
} */

#navigation-bar-mobile > .menu {
  position: fixed;
  background-color: #00283F;
  color: white;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0;
}

#navigation-bar-mobile > .menu.is-open {
  z-index: 10;
  opacity: 1;
}

#navigation-bar-mobile > .menu .close {
  font-size: 24px;
  margin-right: 24px;
  margin-top: 16px;
}

#navigation-bar-mobile > .menu > .links {
  list-style: none;
}
.form-select {
  background-color: #00283f;
  color: #fff;
  border-color: #00283f;
}

@media (max-width: 992px) {
  #navigation-bar-mobile {
      display: block;
  }
}
