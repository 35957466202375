#navigation-bar .navbar-brand {
    padding-left: 50px;
    background-image: none;
}

#navigation-bar #navbarNav {
    margin-left: 100px;
}

#navigation-bar .nav-item {
    margin-left: 15px;
}

#navigation-bar a {
    text-decoration: none;
    background-image: linear-gradient(#00283F, currentColor);
    background-position: 0% 100%;
    background-repeat: no-repeat;
    background-size: 0% 1px;
    transition: background-size .3s;
}

#navigation-bar a:after {
    color: white;
    mix-blend-mode: difference;
}

#navigation-bar a:hover {
    background-size: 100% 1px;
}

#navigation-bar .lang-select {
    margin-bottom: 0;
}

#navigation-bar select {
    padding: 0;
    border: none;
    background: none;
    color: white;
}

#navigation-bar select > option {
    border: none;
    padding: 5px;
    background: #FFFFFF;
    color: #00283F;
}

#navigation-bar .fas {
    color: white;
}

@media (max-width: 1165px) {
    #navigation-bar #navbarNav {
        margin-left: 30px;
    }
}

@media (max-width: 1080px) {
    #navigation-bar .nav-item {
        margin-left: 5px;
    }
}

@media (max-width: 992px) {
    #navigation-bar {
        display: none !important;
    }
}
