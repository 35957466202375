#header {
    margin-top: -150px;
}

#header .bg-area {
    flex: 0.4;
    height: 700px;
    background-color: #00283F;    
    background-repeat: no-repeat;
    background-size: cover;
    padding-left: 0;
}

#header .carousel-holder {
    position: relative;
    margin-top: -550px;
    margin-bottom: 100px;
}

#header .carousel,
#header .carousel img {
    background-size: cover;
    object-fit: cover;
    height: 480px;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
}

#header .carousel .carousel-slide {
    position: relative;
}

#header .carousel .carousel-slide > .carousel-slide-details {
    position: absolute;
    top: 100px;
    left: 50px;
}

#header .carousel .carousel-slide > .carousel-slide-details > h1 {
    font-weight: bold;   
    font-size: 50px;
    margin-bottom: 20px;
}

#header .carousel .carousel-slide > .carousel-slide-details > p {
    padding-right: 250px;
}

#header .arrows {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

#header .arrows > .counter {
    color: white;
    margin-right: 16px;
    margin-top: 16px;
    font-size: 10pt;
}

#header .arrows > .carousel-control-prev {
    margin-left: 110px;
}

#header .arrows > .carousel-control-next {
    margin-right: 110px;
}

#header .arrows > .arrow {
    margin-bottom: -200px;
    width: 15%;
}

#header .rec-slider-container {
    margin: 0 !important;
}

#header .social-icons {
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 20%;
    right: 20px;
}

#header .icon-foo {
    text-decoration: none;
}

#header div.rec-pagination {
    display: none;
  }

@media (max-width: 1130px) {
    #header .social-icons {
        display: none;
    }

    #header .arrows > .arrow {
        margin-bottom: -200px;
    }
}  

@media (max-width: 700px) {
    #header .bg-area {
        flex: 1 2;
        transition: 1s;
    }

    #header .carousel .carousel-slide > .carousel-slide-details > h1 {
        font-size: 38px;  
    }
    
    #header .carousel .carousel-slide > .carousel-slide-details > p {
        padding-right: 100px;
    }
    
    #header .arrows > .carousel-control-prev {
        margin-left: 20px;
    }
    
    #header .arrows > .carousel-control-next {
        margin-right: 20px;
    }
}

@media (max-width: 480px) {
    #header .carousel .carousel-slide > .carousel-slide-details > p {
        padding-right: 10px; 
    }
}
