#services {
    margin-bottom: 30vh;
}

#services .title {
    margin-bottom: 70px;
}

#services .activities {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 280px;
    height: 320px;
    background: #F3F3F3;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    margin: 15px;
    box-sizing: border-box;
	transition: .4s;
    color: #00283F;
}  

#services .activities:hover {
    background: #00283F;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    color: white;
}

#services .activities__icon {
    fill:#00283F;
}

#services .activities:hover .activities__icon {
    fill: white;
}

#services .row {
    margin: 0 30px 0 30px;
}

.hr-line {
    margin: 20px 60px;
	border-top: 1.5px solid #00283F;
    transition: .4s;
}

@media (max-width: 770px) {
    #services .activities {
        height: 200px;
    }
}