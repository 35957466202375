#news .card {
  width: 100%;
}

#news h1 {
  margin-top: -100px;
  margin-bottom: 120px;
  color: #00283f;
  font-weight: 700;
}

#news .card-img-top,
#news .card {
  border-radius: 0;
}

#news .card-img-top {
  width: 100%;
  height: 20vw;
  object-fit: cover;
}

#news .img-1,
#news .img-2 {
  height: 30vw;
}

#news .row:not(.row-not) {
  margin-bottom: 50px;
}

#news .row1 p:not(.not) {
  font-weight: 500;
  font-size: large;
}

#news .card a {
  color: #054468;
  text-decoration: none;
}

#news a:hover {
  text-decoration: underline;
  text-underline-position: under;
}

#news .row2 a {
  font-size: small;
}

#news .card a {
  text-align: right;
}

#news .card-cstm {
  position: relative;
}

#news .card-body-cstm {
  background-color: white;
  position: absolute;
  bottom: 0;
  width: 90%;
}

@media (max-width: 767px) {
  #news .news-card {    
    margin-bottom: 30px;
  }

  #news .img-1,
  #news .img-2 {
    height: 50vw;
  }

  #news .row2 .card-img-top {
    height: 30vw;
  }
}

@media (max-width: 700px) {
  #news .news-card {    
    margin-bottom: 30px;
  }

  #news .img-1,
  #news .img-2 {
    height: 70vw;
  }

  #news .row2 .card-img-top {
    height: 50vw;
  }

  #news .row1 p:not(.not) {
    font-weight: 500;
    font-size: medium;
  }
}
